import classNames from "classnames";
import { useFeature } from "flagged";
import React from "react";
import { FileDrop } from "react-file-drop";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Column } from "react-table";

import workflowStyle from "./all-workflows-table.scss";
import ConnectedWorkflowsList from "./ConnectedWorkflowsList";
import ProductNameColumn from "./ProductNameColumn";
import SubWorkflowList from "./SubWorkflowList";
import WorkflowEventDatePicker from "./WorkflowEventDatePicker";
import Chevron from "components/icons/Chevron";
import StarGreenIcon from "components/icons/StarGreenIcon";
import StarWhiteIcon from "components/icons/StarWhiteIcon";
import KebabMenu from "components/kebab-menu/KebabMenu";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import FeatureUpdateModal from "components/modal/FeatureUpdateModal";
import Modal from "components/modal/Modal";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import ActiveWorkflowUserList from "components/workflows/ActiveWorkflowUserList";
import ImportWorkflowsDialog from "components/workflows/ImportWorkflowsDialog";
import { compareEditorVersionDtos } from "components/workflows/manage-workflow-dialog/ManageWorkflowDialog";
import ManageWorkflowWrapper from "components/workflows/manage-workflow-dialog/ManageWorkflowWrapper";
import WorkflowKebabMenu from "components/workflows/WorkflowKebabMenu";
import { AUTH_USER_VIEW } from "domain/authority";
import { FeatureLicenseType } from "domain/users";
import { ConnectedWorkflows, FilterState, WorkflowTableData } from "domain/workflows";
import { FLAG_CONNECTED_WORKFLOWS, FLAG_NEW_WORKFLOW_EDITOR } from "services/feature/FeatureFlagService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import {
    EditorGeneration,
    Profile,
    profileToFeatureLicenseMap,
    profileToNameMap,
    WorkflowEditorDto,
    workflowService,
    WorkflowService,
} from "services/workflows/WorkflowService";
import { store, StoreState } from "store";
import { setUser } from "store/user";
import buttonStyle from "styles/buttons.scss";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import illustration from "assets/images/update-workflows-dialog/illustration.svg";
import improvements from "assets/images/update-workflows-dialog/improvements.svg";

import testIds from "testIds.json";

interface TableState {
    workflowsData: WorkflowTableData[];
    count: number;
    cursor: string;
    scrollPosition: number;
}

export interface LatestEditorVersions {
    bde: string;
    bmde: string;
    bmdeDiag: string;
}

const mapState = (state: StoreState) => ({
    user: state.userReducer.user,
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
});
const connector = connect(mapState, { setUser });

const AllWorkflowsTable = (
    props: ConnectedProps<typeof connector> & {
        count: number;
        search: string;
        filterState?: FilterState;
        setFilterState?: React.Dispatch<React.SetStateAction<FilterState>>;
        onUpdate: () => void;
        workflowService: WorkflowService;
        connectedWorkflows?: boolean;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const [tableState, setTableState] = React.useState<TableState>({
        workflowsData: [],
        count: 0,
        cursor: "",
        scrollPosition: 0,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
    const [workflowUuid, setWorkflowUuid] = React.useState<string>();
    const [workflowProduct, setWorkflowProduct] = React.useState("");
    const [workflowVersion, setWorkflowVersion] = React.useState("");
    const [editorGeneration, setEditorGeneration] = React.useState<EditorGeneration | undefined>(undefined);
    const [manageDialogVisible, setManageDialogVisible] = React.useState(false);
    const [importDialogVisible, setImportDialogVisible] = React.useState(false);
    const [importWorkflowFileList, setImportWorkflowFileList] = React.useState<FileList | undefined>(undefined);
    const [search, setSearchQuery] = React.useState("");
    const newWorkflowEditor = useFeature(FLAG_NEW_WORKFLOW_EDITOR);
    const [createWorkflowVisibility, setCreateWorkflowVisibility] = React.useState(false);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [showWorkflowUpdateDialog, setShowWorkflowUpdateDialog] = React.useState(false);
    const workflowUpdateDialogStatus = useSelector(
        (state: StoreState) => state.userReducer.user?.showWorkflowUpdateDialog == true
    );
    const [workflowEditors, setWorkflowEditors] = React.useState<WorkflowEditorDto[]>([]);
    const [latestEditorVersions, setLatestEditorVersions] = React.useState<LatestEditorVersions>();
    const [optionList, setOptionList] = React.useState<JSX.Element[] | undefined>(undefined);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [selectedVersionSearch, setSelectedVersionSearch] = React.useState("");
    const [selectedProductSearch, setSelectedProductSearch] = React.useState("");
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const connectedWorkflowsFlag = useFeature(FLAG_CONNECTED_WORKFLOWS);
    const [editWorkflowVisible, setEditWorkflowVisible] = React.useState(false);
    const [selectedConnectedData, setSelectedConnectedData] = React.useState<ConnectedWorkflows | undefined>(undefined);
    const [modalTitle, setModalTitle] = React.useState<string>(t("ImportWorkflowDialog.title"));
    const [modalTitleIcon, setModalTitleIcon] = React.useState<JSX.Element | undefined>(undefined);

    const showManageDialog = (
        uuid: string,
        generation: EditorGeneration,
        version: string,
        product: string,
        connectedWorkflows: ConnectedWorkflows
    ) => {
        setSelectedConnectedData(connectedWorkflows);
        setWorkflowUuid(uuid);
        setEditorGeneration(generation);
        setWorkflowVersion(version);
        setWorkflowProduct(product);

        if (connectedWorkflowsFlag) {
            if (connectedWorkflows.parentWorkflows.length > 0) {
                setEditWorkflowVisible(true);
            } else {
                setManageDialogVisible(true);
            }
        } else {
            setManageDialogVisible(true);
        }
    };

    const hideImportDialog = () => {
        setImportDialogVisible(false);
        setImportWorkflowFileList(undefined);
    };
    const showImportDialog = (fileList: FileList) => {
        setImportWorkflowFileList(fileList);
        setImportDialogVisible(true);
    };

    const handleStartDateChange = (date: string) => {
        handleFilterChanges({ from: date });
    };

    const handleEndDateChange = (date: string) => {
        handleFilterChanges({ to: date });
    };

    const handleFilterChanges = (update: Partial<FilterState>) => {
        if (props.setFilterState) {
            props.setFilterState((prevState) => ({
                ...prevState,
                ...update,
            }));
        }
    };
    const versionValue = selectedVersionSearch.includes("Erasure")
        ? selectedVersionSearch.replace("Erasure", "")
        : selectedVersionSearch.replace("Diagnostics", "");

    const fetchData = (initialLoading: boolean, abortController: AbortController) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        props.workflowService
            .fetchWorkflows(
                search,
                initialLoading ? "" : tableState.cursor,
                selectedProductSearch.toUpperCase(),
                versionValue.trimEnd(),
                props.filterState?.from,
                props.filterState?.to,
                abortController
            )
            .then((data) => {
                setTableState((prevState) => ({
                    ...prevState,
                    scrollPosition: prevState.workflowsData.length - 1,
                    workflowsData: prevState.workflowsData.concat(data.workflowTableData),
                    count: data.count,
                    cursor: data.cursor,
                }));
                setLoading(false);
                setRequestFailureMessage("");
                if (newWorkflowEditor) {
                    setShowWorkflowUpdateDialog(workflowUpdateDialogStatus);
                }
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("WorkflowsTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };

    const handleFileDrop = (files: FileList) => {
        if (files.length === 0) {
            return;
        }
        usageStatisticsService.sendEvent({
            category: Category.WORKFLOW,
            action: Action.IMPORT_WORKFLOW,
        });
        showImportDialog(files);
    };

    const updateShowWorkflowUpdateDialogValue = () => {
        if (props.user) {
            const updatedUser = { ...props.user, showWorkflowUpdateDialog: false };
            props.setUser(updatedUser);
            userSessionService.storeUser(updatedUser);
        }
    };

    const handleTryNewWorkflowEditor = () => {
        const abortController = new AbortController();
        usageStatisticsService.sendEvent({
            category: Category.NEW_WORKFLOW_EDITOR,
            action: Action.TRY_NEW_WORKFLOW_EDITOR,
        });
        if (props.user) {
            props.workflowService.updateShowUpdateWorkflowsDialogStatus(props.user.uuid, abortController);
        }

        setShowWorkflowUpdateDialog(false);
        setCreateWorkflowVisibility(true);
        updateShowWorkflowUpdateDialogValue();
    };

    const hideFeatureUpdateModal = () => {
        const abortController = new AbortController();
        usageStatisticsService.sendEvent({
            category: Category.NEW_WORKFLOW_EDITOR,
            action: Action.CLOSE_UPDATE_WORKFLOWS_DIALOG,
        });
        if (props.user) {
            props.workflowService.updateShowUpdateWorkflowsDialogStatus(props.user.uuid, abortController);
        }
        setShowWorkflowUpdateDialog(false);
        updateShowWorkflowUpdateDialogValue();
    };

    const createDropdownArrow = (isExpanded: boolean) => {
        const theme = store.getState();
        return (
            <div>
                <Chevron
                    color={theme.themeReducer.theme.iconFillColor}
                    className={classNames(workflowStyle.toggleArrow, workflowStyle.down, {
                        [workflowStyle.up]: isExpanded,
                    })}
                    width={20}
                    height={20}
                />
            </div>
        );
    };

    const columns: Array<Column<WorkflowTableData>> = [];
    columns.push(
        {
            Header: () => <TextWithTooltip text={t("WorkflowsTable.name")} key="name" />,
            accessor: "name",
            Cell: (cellInfo) => (
                <>
                    <KebabMenu>
                        <WorkflowKebabMenu
                            version={cellInfo.cell.row.original.editorVersion}
                            uuid={cellInfo.cell.row.original.uuid}
                            product={cellInfo.cell.row.original.profile}
                            name={cellInfo.cell.row.original.name}
                            own={cellInfo.cell.row.original.own}
                            defaultWorkflow={cellInfo.cell.row.original.defaultWorkflow.own}
                            onUpdate={props.onUpdate}
                            workflowService={workflowService}
                            editorGeneration={cellInfo.row.original.editorGeneration}
                            connectedWorkflows={cellInfo.row.original.connectedWorkflows}
                            updateVisible={
                                cellInfo.cell.row.original.editorGeneration == "V2"
                                    ? cellInfo.cell.row.original.profile === Profile.BDE
                                        ? cellInfo.cell.row.original.editorVersion !== latestEditorVersions?.bde
                                        : cellInfo.cell.row.original.profile === Profile.BMDE
                                        ? cellInfo.cell.row.original.editorVersion !== latestEditorVersions?.bmde
                                        : cellInfo.cell.row.original.editorVersion !== latestEditorVersions?.bmdeDiag
                                    : true
                            }
                        />
                    </KebabMenu>
                    <button
                        className={workflowStyle.nameLinkContainer}
                        onClick={() => {
                            showManageDialog(
                                cellInfo.cell.row.original.uuid,
                                cellInfo.cell.row.original.editorGeneration,
                                cellInfo.cell.row.original.editorVersion,
                                cellInfo.cell.row.original.profile,
                                cellInfo.cell.row.original.connectedWorkflows
                            );
                        }}
                    >
                        <TextWithTooltip text={cellInfo.value} />
                    </button>
                </>
            ),
            width: deriveColumnWidth(30, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("WorkflowsTable.product")} key="product" />,
            accessor: "product",
            Cell: ({ cell: { row } }) => (
                <ProductNameColumn
                    profile={row.original.product.toLowerCase()}
                    version={row.original.editorVersion}
                    generation={row.original.editorGeneration}
                    t={t}
                />
            ),
            width: deriveColumnWidth(30, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("WorkflowsTable.createdDate")} key="createdDate" />,
            accessor: "createdDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
            width: deriveColumnWidth(20, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("WorkflowsTable.creator")} key="creator" />,
            accessor: "creator",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(35, tableContainerRef),
        }
    );
    (connectedWorkflowsFlag || props.connectedWorkflows) &&
        columns.push({
            Header: () => (
                <TextWithTooltip
                    text={t("WorkflowsTable.connectedWorkflows.title")}
                    key="defaultWorkflow.connectedWorkflows"
                />
            ),
            accessor: "connectedWorkflows",
            Cell: ({ cell: { value } }) => {
                return value.totalConnectedWorkflows ? (
                    <ConnectedWorkflowsList connectedWorkflows={value} isSubWorkflow={false} />
                ) : (
                    <></>
                );
            },
            width: deriveColumnWidth(25, tableContainerRef),
        });
    columns.push({
        Header: () => <TextWithTooltip text={t("WorkflowsTable.activeWorkflow")} key="defaultWorkflow.other" />,
        accessor: "defaultWorkflow",
        id: "defaultWorkflow.other.usernames",
        Cell: ({ cell: { value, row } }) => {
            const own = value.own;
            const usernames = value.other.userDetails;
            const buttenRef = React.useRef(null);
            return (
                <>
                    <TextWithTooltip text={""}>
                        {own === true && <StarGreenIcon color={theme.defaultStarColor} />}

                        {usernames.length > 0 && (
                            <>
                                {own != true ? <StarWhiteIcon color={theme.userDefaultStarColor} /> : "+"}
                                <div className={workflowStyle.activeWorkflowColumn}>
                                    {t("WorkflowsTable.users", { count: value.other.count })}
                                </div>
                                {userSessionService.userHasAllAuthorities([AUTH_USER_VIEW]) && (
                                    <button
                                        ref={buttenRef}
                                        onClick={() => {
                                            row.toggleRowExpanded();
                                            usageStatisticsService.sendEvent({
                                                category: Category.WORKFLOW,
                                                action: Action.SHOW_USER_LIST_IN_WORKFLOW,
                                            });
                                        }}
                                        data-testid={testIds.workArea.workflows.table.defaultList.button}
                                        aria-expanded={row.isExpanded}
                                    >
                                        {createDropdownArrow(row.isExpanded)}
                                    </button>
                                )}

                                {row.isExpanded && (
                                    <ActiveWorkflowUserList
                                        status={buttenRef}
                                        userDetails={row.original.defaultWorkflow.other.userDetails}
                                    />
                                )}
                            </>
                        )}
                    </TextWithTooltip>
                </>
            );
        },
        width: deriveColumnWidth(20, tableContainerRef),
    });

    React.useEffect(() => {
        const getLatestEditorVersion = (product: Profile): string => {
            return workflowEditors
                .filter((each) => each.editor_generation == "V2")
                .filter((editor) =>
                    product == Profile.BDE ? editor.profile === product : editor.profile != Profile.BDE
                )
                .sort(compareEditorVersionDtos)
                .filter((obj, index, self) => index === self.findIndex((each) => each.version === obj.version))[0]
                .version;
        };

        if (workflowEditors.length > 0) {
            const latestEditors: LatestEditorVersions = {
                bde: getLatestEditorVersion(Profile.BDE),
                bmde: getLatestEditorVersion(Profile.BMDE),
                bmdeDiag: getLatestEditorVersion(Profile.BMDE_DIAGNOSTIC),
            };

            setLatestEditorVersions(latestEditors);
        }
    }, [workflowEditors]);

    React.useEffect(() => {
        const abortController = new AbortController();
        abortControllers.push(abortController);

        props.workflowService.fetchWorkflowEditors(Profile.ALL, abortController).then((workflowEditors) => {
            setWorkflowEditors(workflowEditors);
        });
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    React.useEffect(() => {
        setManageDialogVisible(false);
        setEditWorkflowVisible(false);
        setCreateWorkflowVisibility(false);
        const abortController = new AbortController();
        setTableState({ workflowsData: [], count: 0, cursor: "", scrollPosition: 0 });
        fetchData(true, abortController);
        return () => {
            abortController.abort();
        };
    }, [
        props.count,
        search,
        selectedProductSearch.toUpperCase(),
        selectedVersionSearch,
        props.filterState?.from,
        props.filterState?.to,
    ]);

    React.useEffect(() => {
        const sortedEditors = workflowEditors
            .filter((editor) =>
                selectedProductSearch == Profile.BDE
                    ? editor.profile === selectedProductSearch
                    : editor.profile != Profile.BDE
            )
            .sort(compareEditorVersionDtos);

        const removeDuplicateVersion = sortedEditors.filter(
            (obj, index, self) => index === self.findIndex((each) => each.version === obj.version)
        );

        setOptionList(
            removeDuplicateVersion.map((editor) => (
                <option key={editor.version + ":" + editor.profile}>{editor.version}</option>
            ))
        );
    }, [selectedProductSearch]);

    let dataCount = null;
    if (tableState.workflowsData.length > 0) {
        dataCount = t("Common.recordsCount", { dataCount: tableState.workflowsData.length });
    }

    const table = (
        <div
            className={classNames(
                loading && initialLoading ? workflowStyle.loadingIndicator : "",
                workflowStyle.tableStyle
            )}
        >
            <Table
                tableIdentity={RepositoryKey.WORKFLOWS_TABLE}
                data={tableState.workflowsData}
                columns={columns}
                loaded={!initialLoading}
                loading={loading}
                failureMessage={requestFailureMessage}
                tooltips={true}
                scrollTo={tableState.scrollPosition}
                emptyMessage={t("WorkflowsTable.emptyStateMessage")}
                data-testId={testIds.workArea.workflows.table}
                noAction={true}
            />
        </div>
    );
    const tableWrapper = hasTenantCookie() ? table : <FileDrop onDrop={handleFileDrop}>{table}</FileDrop>;

    return (
        <>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>{dataCount}</div>
                <div className={workflowStyle.filter}>
                    <span className={workflowStyle.label}>{t("WorkflowsTable.filter.filterBy")}</span>
                    <div className={formStyle.search}>
                        <select
                            id={"product"}
                            value={selectedProductSearch}
                            onChange={(event) => {
                                setSelectedProductSearch(event.target.value);
                                setSelectedVersionSearch("");
                            }}
                            className={classNames(formStyle.select, formStyle.fixedWidthInput)}
                            data-testid={testIds.workArea.workflows.filter.productSelect}
                        >
                            <option key={"empty"} value={""}>
                                {t("WorkflowsTable.filter.allProducts")}
                            </option>
                            {Object.values(Profile).map((each, index) => {
                                if (
                                    Profile.ALL !== each &&
                                    props.tenantDetails?.featureLicenses.includes(
                                        profileToFeatureLicenseMap.get(each) as FeatureLicenseType
                                    )
                                ) {
                                    return (
                                        <option key={index} value={each}>
                                            {profileToNameMap.get(each)}
                                        </option>
                                    );
                                }
                            })}
                        </select>
                    </div>
                    <div className={formStyle.search}>
                        <select
                            name="version"
                            value={selectedVersionSearch}
                            onChange={(e) => setSelectedVersionSearch(e.target.value)}
                            className={classNames(form.select, workflowStyle.fixedWidthVersion)}
                            disabled={selectedProductSearch === ""}
                            data-testid={testIds.workArea.workflows.filter.versionSelect}
                        >
                            <option key={"value"} value={""}>
                                {t("WorkflowsTable.filter.allVersions")}
                            </option>
                            {optionList}
                        </select>
                    </div>
                    <div>
                        <WorkflowEventDatePicker from={handleStartDateChange} to={handleEndDateChange} />
                    </div>

                    <div className={formStyle.search}>
                        <SearchView setSearch={setSearchQuery} searchInProgress={false} />
                    </div>
                </div>
            </div>
            <div className={layoutStyle.tableWrapper}>{tableWrapper}</div>
            {tableState.cursor != null &&
                tableState.workflowsData.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(buttonStyle.primaryButton, buttonStyle.loadMoreButton)}
                        onClick={() => {
                            fetchData(false, new AbortController());
                            usageStatisticsService.sendEvent({
                                label: "workflows",
                                action: Action.LOAD_MORE,
                                category: Category.WORKFLOW,
                            });
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
            <ManageWorkflowWrapper
                modalTitle={"ManageWorkflowDialog.title.manage"}
                modalVisibility={manageDialogVisible}
                onUpdate={props.onUpdate}
                workflowUuid={workflowUuid}
                editorGeneration={editorGeneration}
                workflowService={props.workflowService}
                onShowModal={setManageDialogVisible}
                isEdit={true}
                product={workflowProduct}
                version={workflowVersion}
                parentWorkflowsCount={selectedConnectedData?.parentWorkflows.length}
            />
            <Modal
                isOpen={importDialogVisible}
                hideModal={hideImportDialog}
                modalTitle={modalTitle}
                titleIcon={modalTitleIcon}
            >
                <ImportWorkflowsDialog
                    fileList={importWorkflowFileList != null ? Array.from(importWorkflowFileList) : undefined}
                    onClose={hideImportDialog}
                    workflowService={workflowService}
                    setModalTitle={setModalTitle}
                    setModalTitleIcon={setModalTitleIcon}
                />
            </Modal>
            <SubWorkflowList
                visible={editWorkflowVisible}
                hide={setEditWorkflowVisible}
                openManageDialogVisible={setManageDialogVisible}
                connectedWorkflows={selectedConnectedData}
            />
            <FeatureUpdateModal
                open={showWorkflowUpdateDialog}
                hide={hideFeatureUpdateModal}
                title={t("WorkflowsUpdateDialog.title")}
                banner={<img src={illustration} />}
                improvements={[
                    {
                        title: t("WorkflowsUpdateDialog.improvements.improvedWorkflowEditor.title"),
                        icon: <img src={improvements} />,
                        message: <>{t("WorkflowsUpdateDialog.improvements.improvedWorkflowEditor.message")}</>,
                    },
                ]}
                actions={
                    <>
                        <button
                            className={classNames(buttons.primaryButton, buttons.small)}
                            onClick={handleTryNewWorkflowEditor}
                            data-testId={testIds.workArea.workflows.workflowFeatureUpdateDialog.tryItNowButton}
                        >
                            {t("WorkflowsUpdateDialog.tryItNowButton")}
                        </button>
                    </>
                }
                testId={testIds.workArea.workflows.workflowFeatureUpdateDialog.itself}
            />
            {createWorkflowVisibility && (
                <ManageWorkflowWrapper
                    modalTitle={"ManageWorkflowDialog.title.create"}
                    modalVisibility={createWorkflowVisibility}
                    onUpdate={props.onUpdate}
                    workflowService={workflowService}
                    user={props.user}
                    onShowModal={setCreateWorkflowVisibility}
                    onCreate={true}
                    tryNewEditor={true}
                />
            )}
        </>
    );
};

export default connector(AllWorkflowsTable);
