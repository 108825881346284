import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Column } from "react-table";

import style from "./templates.scss";
import TemplatesKebabMenu from "./TemplatesKebabMenu";
import KebabMenu from "components/kebab-menu/KebabMenu";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import workflowStyle from "components/workflows/all-workflows-table.scss";
import ConnectedWorkflowsList from "components/workflows/ConnectedWorkflowsList";
import { compareEditorVersionDtos } from "components/workflows/manage-workflow-dialog/ManageWorkflowDialog";
import ProductNameColumn from "components/workflows/ProductNameColumn";
import WorkflowEventDatePicker from "components/workflows/WorkflowEventDatePicker";
import { AUTH_WORKFLOW_TEMPLATE_EDIT, AUTH_WORKFLOW_TEMPLATE_EXPORT } from "domain/authority";
import { FeatureLicenseType } from "domain/users";
import { FilterState } from "domain/workflows";
import { TemplateTableData } from "domain/workflowTemplates";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { PublishStatuses, templatesService } from "services/workflows/TemplatesService";
import {
    Profile,
    profileToFeatureLicenseMap,
    profileToNameMap,
    WorkflowEditorDto,
    workflowService,
} from "services/workflows/WorkflowService";
import { StoreState } from "store";
import { setUser } from "store/user";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import form from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    user: state.userReducer.user,
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
});
const connector = connect(mapState, { setUser });
interface TableState {
    workflowTemplatesData: TemplateTableData[];
    count: number;
    cursor: string;
    scrollPosition?: number;
}
const TemplatesTable = (
    props: ConnectedProps<typeof connector> & {
        count: number;
        search: string;
        filterState?: FilterState;
        setFilterState?: React.Dispatch<React.SetStateAction<FilterState>>;
    }
): JSX.Element => {
    {
        const { t } = useTranslation();
        const [loading, setLoading] = React.useState<boolean>(false);

        const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
        const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
        const [workflowEditors, setWorkflowEditors] = React.useState<WorkflowEditorDto[]>([]);
        const [tableState, setTableState] = React.useState<TableState>({
            workflowTemplatesData: [],
            count: 0,
            cursor: "",
            scrollPosition: 0,
        });
        const [selectedVersionSearch, setSelectedVersionSearch] = React.useState("");
        const [selectedPublishStatus, setSelectedPublishStatus] = React.useState("");
        const [selectedProductSearch, setSelectedProductSearch] = React.useState("");
        const [optionList, setOptionList] = React.useState<JSX.Element[] | undefined>(undefined);
        const [search, setSearchQuery] = React.useState("");

        const handleStartDateChange = (date: string) => {
            handleFilterChanges({ from: date });
        };
        const handleEndDateChange = (date: string) => {
            handleFilterChanges({ to: date });
        };
        const versionValue = selectedVersionSearch.includes("Erasure")
            ? selectedVersionSearch.replace("Erasure", "")
            : selectedVersionSearch.replace("Diagnostics", "");

        const handleFilterChanges = (update: Partial<FilterState>) => {
            if (props.setFilterState) {
                props.setFilterState((prevState) => ({
                    ...prevState,
                    ...update,
                }));
            }
        };

        const columns: Array<Column<TemplateTableData>> = [
            {
                Header: () => <TextWithTooltip text={t("WorkflowTemplates.template.name")} key="1" />,
                accessor: "name",
                Cell: (cellInfo) => (
                    <>
                        {userSessionService.userHasAnyAuthority([
                            AUTH_WORKFLOW_TEMPLATE_EDIT,
                            AUTH_WORKFLOW_TEMPLATE_EXPORT,
                        ]) && (
                            <KebabMenu>
                                <TemplatesKebabMenu />
                            </KebabMenu>
                        )}
                        <button className={style.templateNameCell}>
                            <TextWithTooltip text={cellInfo.value} />
                        </button>
                    </>
                ),
            },
            {
                Header: () => <TextWithTooltip text={t("WorkflowTemplates.subWorkflows.title")} key="2" />,
                accessor: "connectedWorkflows",
                Cell: ({ cell: { value } }) => {
                    return value.totalConnectedWorkflows ? (
                        <ConnectedWorkflowsList connectedWorkflows={value} isSubWorkflow={false} />
                    ) : (
                        <></>
                    );
                },
            },
            {
                Header: () => <TextWithTooltip text={t("Common.status")} key="3" />,
                accessor: "published",
                Cell: (cellInfo) => (
                    <TextWithTooltip
                        text={
                            cellInfo.value == true
                                ? t("WorkflowTemplates.template.published")
                                : t("WorkflowTemplates.template.unpublished")
                        }
                    />
                ),
            },
            {
                Header: () => <TextWithTooltip text={t("WorkflowTemplates.template.createdBy")} key="4" />,
                accessor: "userName",
                Cell: (cellInfo) => <TextWithTooltip text={cellInfo.value} />,
            },
            {
                Header: () => <TextWithTooltip text={t("WorkflowTemplates.template.product")} key="5" />,
                accessor: "profile",
                Cell: ({ cell: { row } }) => (
                    <ProductNameColumn
                        profile={row.original.profile.toLowerCase()}
                        version={row.original.editorVersion}
                        generation={row.original.editorGeneration}
                        t={t}
                    />
                ),
            },
            {
                Header: () => <TextWithTooltip text={t("Common.creationDate")} key="6" />,
                accessor: "created",
                Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
            },

            {
                Header: () => <TextWithTooltip text={t("WorkflowTemplates.template.lastModified")} key="7" />,
                accessor: "modified",
                Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
            },
            {
                Header: () => <TextWithTooltip text={t("WorkflowTemplates.template.usageCount")} key="8" />,
                accessor: "usageCount",
                Cell: (cellInfo) => <TextWithTooltip text={cellInfo.value} />,
            },
        ];

        const fetchData = (initialLoading: boolean, abortController: AbortController) => {
            setLoading(true);
            setInitialLoading(initialLoading);
            templatesService
                .fetchWorkflowTemplates(
                    search,
                    initialLoading ? "" : tableState.cursor,
                    selectedProductSearch.toUpperCase(),
                    selectedPublishStatus,
                    versionValue.trimEnd(),
                    props.filterState?.from,
                    props.filterState?.to,
                    false,
                    abortController
                )
                .then((data) => {
                    setTableState((prevState) => ({
                        ...prevState,
                        scrollPosition: prevState.workflowTemplatesData.length - 1,
                        workflowTemplatesData: prevState.workflowTemplatesData.concat(data.workflowTemplatesTableData),
                        count: data.count,
                        cursor: data.cursor,
                    }));
                    setLoading(false);
                    setRequestFailureMessage("");
                })
                .catch(() => {
                    if (!abortController.signal.aborted) {
                        setRequestFailureMessage(t("WorkflowTemplates.template.requestFailed"));
                    }
                })
                .finally(() => {
                    if (!abortController.signal.aborted) {
                        setLoading(false);
                        setInitialLoading(false);
                    }
                });
        };

        React.useEffect(() => {
            const abortController = new AbortController();

            workflowService.fetchWorkflowEditors(Profile.ALL, abortController).then((workflowEditors) => {
                setWorkflowEditors(workflowEditors);
            });
            return () => {
                abortController.abort();
            };
        }, []);

        React.useEffect(() => {
            const abortController = new AbortController();
            setTableState({ workflowTemplatesData: [], count: 0, cursor: "", scrollPosition: 0 });
            fetchData(true, abortController);
            return () => {
                abortController.abort();
            };
        }, [
            props.count,
            search,
            selectedProductSearch.toUpperCase(),
            selectedVersionSearch,
            selectedPublishStatus,
            props.filterState?.from,
            props.filterState?.to,
        ]);

        React.useEffect(() => {
            const sortedEditors = workflowEditors
                .filter((editor) =>
                    selectedProductSearch == Profile.BDE
                        ? editor.profile === selectedProductSearch
                        : editor.profile != Profile.BDE
                )
                .sort(compareEditorVersionDtos);

            const removeDuplicateVersion = sortedEditors.filter(
                (obj, index, self) => index === self.findIndex((each) => each.version === obj.version)
            );

            setOptionList(
                removeDuplicateVersion.map((editor) => (
                    <option key={editor.version + ":" + editor.profile}>{editor.version}</option>
                ))
            );
        }, [selectedProductSearch]);
        let dataCount = null;
        if (tableState.workflowTemplatesData.length > 0) {
            dataCount = t("Common.defaultSearchResultHint", { dataCount: tableState.workflowTemplatesData.length });
        }

        return (
            <>
                <div className={layoutStyle.tableWrapper}>
                    <div className={layoutStyle.aboveTable}>
                        <div className={layoutStyle.recordCount}>{dataCount}</div>
                        <div className={workflowStyle.filter}>
                            <span className={workflowStyle.label}>{t("WorkflowsTable.filter.filterBy")}</span>
                            <div className={formStyle.search}>
                                <select
                                    id={"product"}
                                    value={selectedProductSearch}
                                    onChange={(event) => {
                                        setSelectedProductSearch(event.target.value);
                                        setSelectedVersionSearch("");
                                    }}
                                    className={classNames(formStyle.select, formStyle.fixedWidthInput)}
                                    data-testid={testIds.workArea.workflowTemplates.filter.productSelect}
                                >
                                    <option key={"empty"} value={""}>
                                        {t("WorkflowTemplates.filter.allProducts")}
                                    </option>
                                    {Object.values(Profile).map((each, index) => {
                                        if (
                                            Profile.ALL !== each &&
                                            props.tenantDetails?.featureLicenses.includes(
                                                profileToFeatureLicenseMap.get(each) as FeatureLicenseType
                                            )
                                        ) {
                                            return (
                                                <option key={index} value={each}>
                                                    {profileToNameMap.get(each)}
                                                </option>
                                            );
                                        }
                                    })}
                                </select>
                            </div>
                            <div className={formStyle.search}>
                                <select
                                    name="version"
                                    value={selectedVersionSearch}
                                    onChange={(e) => setSelectedVersionSearch(e.target.value)}
                                    className={classNames(form.select, workflowStyle.fixedWidthVersion)}
                                    disabled={selectedProductSearch === ""}
                                    data-testid={testIds.workArea.workflowTemplates.filter.versionSelect}
                                >
                                    <option key={"value"} value={""}>
                                        {t("WorkflowTemplates.filter.allVersions")}
                                    </option>
                                    {optionList}
                                </select>
                            </div>
                            <div className={formStyle.search}>
                                <select
                                    name="published"
                                    value={selectedPublishStatus}
                                    onChange={(e) => setSelectedPublishStatus(e.target.value)}
                                    className={classNames(form.select, workflowStyle.fixedWidthVersion)}
                                    data-testid={testIds.workArea.workflowTemplates.filter.statusSelect}
                                >
                                    <option key={"value"} value={""}>
                                        {t("WorkflowTemplates.filter.allStatuses")}
                                    </option>
                                    {Object.values(PublishStatuses).map((each, index) => {
                                        return (
                                            <option
                                                key={index}
                                                value={PublishStatuses.PUBLISHED == each ? "true" : "false"}
                                            >
                                                {each}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div>
                                <WorkflowEventDatePicker from={handleStartDateChange} to={handleEndDateChange} />
                            </div>

                            <div className={formStyle.search}>
                                <SearchView setSearch={setSearchQuery} searchInProgress={false} />
                            </div>
                        </div>
                    </div>
                    <div
                        className={classNames(
                            loading && initialLoading ? workflowStyle.loadingIndicator : "",
                            workflowStyle.tableStyle
                        )}
                    >
                        <Table
                            tableIdentity={RepositoryKey.WORKFLOW_TEMPLATES_TABLE}
                            data={tableState.workflowTemplatesData}
                            columns={columns}
                            loaded={!initialLoading}
                            loading={loading}
                            failureMessage={requestFailureMessage}
                            tooltips={true}
                            scrollTo={tableState.scrollPosition}
                            emptyMessage={t("WorkflowTemplates.template.emptyStateMessage")}
                            data-testId={testIds.workArea.workflowTemplates.table}
                            noAction={true}
                        />
                    </div>
                </div>
                {tableState.cursor != null &&
                    tableState.workflowTemplatesData.length != 0 &&
                    requestFailureMessage === "" &&
                    (loading ? (
                        <LoadingIndicator small={true} />
                    ) : (
                        <button
                            className={classNames(buttonStyle.primaryButton, buttonStyle.loadMoreButton)}
                            onClick={() => {
                                fetchData(false, new AbortController());
                                usageStatisticsService.sendEvent({
                                    label: Label.WORKFLOW_TEMPLATES,
                                    action: Action.LOAD_MORE,
                                    category: Category.WORKFLOW_TEMPLATE,
                                });
                            }}
                            data-testid={testIds.common.primaryView.table.loadMoreButton}
                        >
                            {t("Common.loadMore")}
                        </button>
                    ))}
            </>
        );
    }
};
export default connector(TemplatesTable);
