import * as React from "react";
import { MutableRefObject, useEffect, useState } from "react";
import useScript from "react-script-hook";

import style from "./visual-editor.scss";
import { compareEditorVersionDtos } from "components/workflows/manage-workflow-dialog/ManageWorkflowDialog";
import { Workflow } from "domain/workflows";
import { Profile, WorkflowEditorDto } from "services/workflows/WorkflowService";

export interface WorkflowEditor {
    serializeWorkflow(): string;
}

interface LoadTarget extends Window {
    WorkflowEditor?: WorkflowEditor;
}

interface Props {
    profile: Profile;
    version?: string;
    workflow?: Workflow;
    workflowEditorReference: MutableRefObject<WorkflowEditor | undefined>;
    workflowEditors: WorkflowEditorDto[];
    setWorkflow: (workflow: Workflow) => void;
}

declare let window: LoadTarget;

const createEditorContainerElement = (editorContainerId: string | undefined = undefined) => {
    // Provide a static ID child div as a workaround for the BMDE diagnostics "Run tests" item.
    // When https://jira.blancco.com/browse/BCC-2511 has been also fixed in the editor side, consider whether it's
    // feasible to include this fix only for the affected editor versions.
    return (
        <div id={editorContainerId} className={style.editorContainer}>
            <div id={"workflowEditor_workflowEditorPanel"} />
        </div>
    );
};

const generateEditorContainerId = (length = 16) => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    let result = "WorkflowEditorContainer-";
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const VisualEditorView: React.FunctionComponent<Props> = (props) => {
    const [editorContainerElement, setEditorContainerElement] = React.useState(createEditorContainerElement());
    const [workflowEditorUrl, setWorkflowEditorUrl] = useState("");
    // Current use of useScript hook works with version 1.5.0 of the library.
    // Before updating it, it used 1.7.0 or higher. So the hook was called only once when rendering the component,
    // which causes the workflow to not be deduced from the URL in the visual editor.
    useScript({
        src: workflowEditorUrl.length > 0 ? workflowEditorUrl : null,
        onload: () => onWorkflowEditorLoaded(),
    });

    const pickWorkflowEditor = (profile: Profile, version?: string) => {
        const sorted = props.workflowEditors
            .filter((editor) => editor.profile === profile && editor.editor_generation == "V1")
            .sort(compareEditorVersionDtos);

        if (version) {
            const editor = sorted.find((editor) => editor.version === version);
            if (editor) {
                return editor;
            } else {
                const versionSplit = version.split(".");
                if (versionSplit.length > 3) {
                    versionSplit.pop();
                    const editor = sorted.find((editor) => editor.version === versionSplit.join("."));
                    if (editor) {
                        return editor;
                    }
                }
            }
        }

        return sorted[0];
    };

    const onWorkflowEditorLoaded = () => {
        const editorContainerId = generateEditorContainerId();
        setEditorContainerElement(createEditorContainerElement(editorContainerId));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        props.workflowEditorReference.current = new (window as { [key: string]: any })["WorkflowEditor"](
            document.getElementById(editorContainerId),
            typeof props.workflow !== "undefined" ? JSON.stringify(props.workflow) : null,
            null
        );
    };

    useEffect(() => {
        setWorkflowEditorUrl(pickWorkflowEditor(props.profile, props.version).url);

        return () => {
            delete window.WorkflowEditor;
            props.workflowEditorReference.current = undefined;
        };
    }, []);

    return editorContainerElement;
};

export default VisualEditorView;
